.question-tooltip {
  .__react_component_tooltip {
    font-family: $font-family-header;
    font-size: $font-caption-sm;
    line-height: $line-height-xxsm;
    text-transform: none;
    padding: 5px 8px;
    border-radius: $border-radius-sm;
    opacity: 1;
    i {
      font-style: normal;
      color: $bt-brand-primary;
    }
    &.type-dark {
      background-color: $text-icon-dark;
      &.place-top {
        &:after {
          border-top-color: $text-icon-dark;
          border-right-width: 6px;
          border-left-width: 6px;
          margin-left: -6px;
        }
      }
      &.place-bottom {
        &:after {
          border-bottom-color: $text-icon-dark;
          border-right-width: 6px;
          border-left-width: 6px;
          margin-left: -6px;
        }
      }
      &.place-right {
        &:after {
          border-right-color: $text-icon-dark;
          border-top-width: 6px;
          border-bottom-width: 6px;
          margin-top: -5px;
        }
      }
      &.place-left {
        &:after {
          border-left-color: $text-icon-dark;
          border-top-width: 6px;
          border-bottom-width: 6px;
          margin-top: -5px;
        }
      }
    }
  }
}
