.dark {
    .or-divider {
        color: $dark-color;
        &__line {
            border-bottom: 1px dashed $dark-color;
        }
    }
    .and-divider {
        color: $dark-color;
        &__line {
            border-bottom: 1px dashed $dark-color;
        }
    }
}
.or-divider {
    color: $text-icon-light-grey;
    font-weight: 400;
    font-size: $font-caption;
    line-height: $line-height-xsm;
    &__line {
        margin-left: $spacer/4;
        height: 2px;
        border-bottom: 1px dashed $text-icon-light-grey;
    }
}
.and-divider {
    color: $text-icon-light-grey;
    text-transform: uppercase;
    font-weight: 400;
    font-size: $font-caption;
    line-height: $line-height-xsm;
    &__line {
        margin-right: $spacer/4;
        &:last-child {
            margin-right: 0;
            margin-left: $spacer/4;
        }
        height: 2px;
        border-bottom: 1px solid $text-icon-light-grey;
    }
}
.dark {
    .input-container {
        input.input {
            border: 1px solid $input-border-color-dark;
            background-color: $input-bg-dark;
            color: $text-icon-light;
            &:hover {
                border: 1px solid $input-hover-border-color-dark;
            }
            &:focus {
                border: 1px solid $input-focus-border-color-dark;
            }
            &::placeholder {
                color: $input-placeholder-color-dark;
            }
            &:disabled {
                border: 1px solid $black-alpha-32;
                color: $text-icon-light-grey;
                & + .input-icon-right {
                    path {
                        fill: $text-icon-light-grey;
                        opacity: $btn-disabled-opacity;
                    }
                }
            }
        }
    }
    .label-switch {
        color: $text-icon-light;
    }
}

.input-container,
.react-datepicker-wrapper .react-datepicker__input-container {
    &.invalid {
        input.input {
            border-color: $danger !important;
        }
    }
    &.success {
        input.input {
            border-color: $success !important;
        }
    }

    input.input {
        border: 1px solid $input-border-color;
        background-color: $input-bg;
        @include transition(all 200ms);
        &:hover {
            border: 1px solid $basic-alpha-48;
        }
        &:focus {
            border: 1px solid $primary;
        }
        border-radius: $border-radius;
        color: $text-icon-dark;
        height: $input-height;
        line-height: $line-height-lg;
        padding: 12px 12px 12px 16px;
        &--wide {
            min-width: 300px;
        }

        &::placeholder {
            color: $input-placeholder-color;
            font-weight: normal;
        }

        &-sm {
            padding: $input-padding-sm;
            line-height: $line-height-sm;
            height: $input-height-sm;
        }
        &-xsm {
            padding: $input-padding-xsm;
            line-height: $line-height-sm;
            height: $input-height-xsm;
            font-size: $font-sm;
        }
        &-lg {
            padding: $input-padding-lg;
            height: $input-height-lg;
        }
    }
}

.form-group {
    margin-bottom: 1rem;
}

.label-switch {
    color: $text-icon-dark;
    font-weight: 500;
}

.setting {
    label {
        font-size: $font-size-base;
        line-height: $line-height-base;
    }
}

label {
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    margin-bottom: 6px;
}
label,
.icon-primary {
    color: $text-icon-dark;
    font-weight: 500;
}
.dark {
    label,
    .icon-primary {
        color: $white;
    }
}

.hljs,
.hljs-header,
.value-editor pre {
    border-radius: $border-radius;
}

.control-label {
    display: block;
    svg {
        vertical-align: bottom;
    }
}

.__react_component_tooltip {
    z-index: 99999999 !important;
    position: fixed;
}

.notification {
    text-align: center;
    justify-content: center;
    align-self: center;
    &__icon {
        color: $bt-brand-secondary;
        font-size: 2em;
    }
    &__text {
        color: $bt-brand-secondary;
        font-weight: bold;
    }
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: $border-radius-sm;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

.badge-info {
    background-color: $bt-brand-primary;
    color: white;
}
