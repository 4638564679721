.panel-rule {
    border-radius: $border-radius-lg;
    background-color: $bg-light200;
    .react-select {
        font-weight: normal;
        width: 190px;
        .react-select__input input {
            font-weight: normal;
        }
        &__menu {
            font-weight: normal;
        }
        &__option {
            &:hover {
                background: $primary-alfa-8 !important;
                color: $text-icon-dark !important;
            }
        }
    }

}
.dark{
  .panel-rule{
    background-color: $bg-dark300;
  }
}

.segment-overrides-title{
    margin-top: -8px;
}

.create-segment-overrides:first-child{
    margin: -.5rem -1.5rem -1rem !important;
}

