.navbar {
  background-color: $navbar-background;
  //background: linear-gradient(to right, $bt-brand-secondary-light, #1d2d3e);
  &.homepage {
    position: absolute;
    left: 0;
    right: 0;
  }
  z-index: 10;
  .ion {
    font-size: 18px;
    color: $nav-icon-color;
  }
  nav svg {
    height: 18px;
    max-width: 21px;
    margin-right: 5px;
    path {
      fill: $nav-icon-color;
    }
  }
}

.navbar-right nav {
  display: flex;
  a {
    display: flex;
    align-items: center;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    max-width: 748px !important;
  }
}
.navbar-brand img {
  margin-bottom: 6px;
  margin-right: 20px;
}
.org-nav {
  .nav-link {
    margin-right: 0;
  }
}
.dark-mode {
  .rc-switch {
    svg {
      height: 16px;
      margin-right: 0;
      z-index: 1000;
    }
    width: $switch-dark-mode-width;
    height: $switch-dark-mode-height;
    border-radius: $border-radius;
    background-color: $switch-dark-mode-bg;
    &:after {
      width: $switcher-dark-mode-width;
      height: $switcher-dark-mode-width;
      top: 4px;
      left: 4px;
      z-index: 100;
      border-radius: $border-radius;
      box-shadow: 0px 2px 2px 0px rgba(100, 116, 139, 0.12);
    }
    &:focus {
      border: none;
      box-shadow: none;
    }
    &:hover {
      background-color: $switch-dark-mode-bg;
    }
    &-checked {
      &:after {
        left: 36px;
        background-color: $switch-dark-mode-bg-dark;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
        &:hover {
          background-color: $switcher-checked-dark-mode-bg;
        }
      }
      &:hover {
        background-color: $switcher-checked-dark-mode-bg;
      }
      background-color: $switcher-checked-dark-mode-bg;
    }
  }
}
.dark {
  .navbar {
    .ion {
      color: $nav-icon-color-dark;
    }
    nav svg {
      path {
        fill: $nav-icon-color-dark;
      }
    }
  }

  .nav-link {
    color: $nav-link-color-dark;
    .nav-link-featured {
      color: $header-color-dark;
      background-color: $button-bg-dark;
      &:hover {
        background-color: $button-bg-dark-hover;
      }
    }

    &.active {
      color: $nav-link-color-active-dark;
    }
    &:hover {
      color: $nav-link-color-hover-dark;
    }
  }
}
.nav-link {
  img {
    max-height: 21px;
  }
  padding: 0;
  font-weight: 500;
  margin-right: 1rem;
  color: $nav-link-color;
  @include transition(opacity, 200ms);
  &:hover {
    color: $nav-link-color-hover;
  }
  &.nav-faint {
    opacity: 0.5;
    &.navbar-brand {
      opacity: 1;
    }
  }
  &.active {
    color: $nav-link-color-active;
  }
  .nav-link-featured {
    height: $switch-dark-mode-height;
    border-radius: $btn-border-radius;
    background-color: $switch-dark-mode-bg;
    &:hover {
      background-color: $bt-brand-faint-hover;
    }

    color: $header-color;
    padding: 5px 20px;
    -moz-transition: opacity 200ms;
    -o-transition: opacity 200ms;
    -webkit-transition: opacity 200ms;
    transition: opacity 200ms;

    transition-property: opacity;

    &:hover {
      border-color: white;
    }
  }
}

.service-status {
  font-size: 14px;

  .uptime-badge {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    color: #f1f1f1 !important;
    text-decoration: none !important;

    .label {
      display: inline-block;
      background: linear-gradient(#5e5e5e, #4c4c4c);
      padding: 2px 5px;
      border-radius: $border-radius-sm 0 0 $border-radius-sm;
    }

    .success {
      display: inline-block;
      background: linear-gradient(#5bc870, #4e9c4e);
      border-radius: 0 $border-radius-sm $border-radius-sm 0;
      padding: 2px 5px;
    }

    .error {
      display: inline-block;
      background: linear-gradient(#dab32a, #cca417);
      border-radius: 0 $border-radius-sm $border-radius-sm 0;
      padding: 2px 5px;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .navbar-nav {
    .nav-link {
      font-size: 0.95em !important;
    }
  }
}
